import React from "react";
import Layout from "./i18n";
import HeaderMinified from "../components/info-site/HeaderMinified";
import Footer from "../components/Footer";
import "../styles/main.css";
import CookieConsent from "../components/CookieConsent";

const DefaultLayout = (props) => {
    const FOOTER_LINKS = [];

    return (
        <Layout {...props}>
            <div className="layout">
                <CookieConsent />
                <HeaderMinified />
                <div className="content">
                    {props.children}
                </div>
                <Footer links={FOOTER_LINKS} showLinkedInLogo={false} page="customer-faq" />
            </div>
        </Layout>
    );
};

export default DefaultLayout;