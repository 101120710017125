import React from "react";
import Faqs from "../styleguide/Faqs";
import { customerFaqData } from "../../data/customerFaqData";
import { FormattedMessage } from "react-intl";
import styles from "../../styles/info-site/FaqPage.module.css";
import Cta from "../styleguide/Cta";
import { navigate } from "gatsby-link";

const CustomerFaqPage = () => {
    const onContact= () => {
        navigate("/contact");
    };

    return (
        <React.Fragment>
            <div className={styles.Faq}>
                <h3><FormattedMessage id="faqTitle" /></h3>
                <Faqs faqData={customerFaqData} feedback={true} />
            </div>
            <div className={styles.FaqContact}>
                <span className={styles.FaqContactTitle}><FormattedMessage id="faqContactTitle" /></span>
                <Cta className={styles.FaqContactCta} text="faqContactCta" onClick={onContact} />
            </div>
        </React.Fragment>
    );
};

export default CustomerFaqPage;