import React from "react";
import CustomerFaqPage from "../components/info-site/CustomerFaqPage";
import Seo from "../components/info-site/Seo";
import Layout from "../layouts/en-minified";


const Faq = props => {
    return (
        <Layout location={props.location}>
            <Seo titleId="faqTitle" descriptionId="faqDescription" />
            <CustomerFaqPage />
        </Layout>
    );
};

export default Faq;