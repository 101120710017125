import React from "react";
import styles from "../../styles/info-site/HeaderMinified.module.css";
import Logo from "../../assets/logo.png";

const HeaderMinified = () => {
    return (
        <header className={styles.HeaderMinified}>
            <div className={styles.HeaderMinifiedPlaceholder}>
                <div className={styles.HeaderMinifiedLogo}>
                    <img className={styles.HeaderMinifiedLogoTyviso} src={Logo} alt="Tyviso.com" />
                </div>
            </div>
        </header>
    );
}

export default HeaderMinified;